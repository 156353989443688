import { defineStore, acceptHMRUpdate } from 'pinia';
import { v4 as createUid } from 'uuid';
import { computed, reactive } from 'vue';

// ************* import UTILS & HELPERS ************* //
import { useRoute } from 'vue-router';

// Images import
import streamdiverOpt from '@/assets/img/optimised/streamdiver.webp';
import streamdiverAlt from '@/assets/img/raw/streamdiver.png';

import magmaOpt from '@/assets/img/optimised/MagmaYoga_Landing.webp';
import magmaAlt from '@/assets/img/raw/MagmaYoga_Landing.png';

import portfolioOpt from '@/assets/img/optimised/portfolio.webp';
import portfolioAlt from '@/assets/img/raw/portfolio.png';

import chatAppOpt from '@/assets/img/optimised/chat-app.webp';
import chatAppAlt from '@/assets/img/raw/chat-app.png';

import tobiasOpt from '@/assets/img/optimised/Tobias_Anderson_Website.webp';
import tobiasAlt from '@/assets/img/raw/Tobias_Anderson_Website.png';

import maptyOpt from '@/assets/img/optimised/Mapty_Screenshot.webp';
import maptyAlt from '@/assets/img/raw/Mapty_Screenshot.png';

import forkifyOpt from '@/assets/img/optimised/Forkify.webp';
import forkifyAlt from '@/assets/img/raw/Forkify.png';

import expenseTrackerOpt from '@/assets/img/optimised/Expense_Tracker.webp';
import expenseTrackerAlt from '@/assets/img/raw/Expense_Tracker.png';

import omnifoodOpt from '@/assets/img/optimised/Omnifood_Project.webp';
import omnifoodAlt from '@/assets/img/raw/Omnifood_Project.png';

import iphoneOpt from '@/assets/img/optimised/Iphone_Webpage.webp';
import iphoneAlt from '@/assets/img/raw/Iphone_Webpage.png';

import enixiOpt from '@/assets/img/optimised/enixi-login.webp';
import enixiAlt from '@/assets/img/raw/enixi-login.png';

import enixiMobileOpt from '@/assets/img/raw/enixi/enixi_iphone.png';
import enxiDesktopOpt from '@/assets/img/raw/enixi/enixi_desktop.png';

export const useProjectsStore = defineStore('projects', () => {
  // id: 'projects-store',
  const state = reactive({
    list: [
      {
        name: 'Enixi',
        id: 'e-1',
        publishedDate: '16.09.2023',
        cardVersion: 'prominent',
        tags: [
          { mode: 'highlight', name: 'professional', id: createUid() },
          { mode: 'elegant', name: 'large-scale', id: createUid() },
        ],
        slideInfos: [
          {
            icon: 'computer-desktop',
            content: 'App developing',
          },
          {
            icon: 'device-phone',
            content: 'PWA',
          },
          {
            icon: 'server',
            content: 'Api integration',
          },
          {
            icon: 'code-bracket',
            content: 'HTML, CSS (Tailwindcss)',
          },
          {
            icon: 'code-bracket',
            content: 'Laravel',
          },
          {
            icon: 'code-bracket',
            content: 'JavaScript, Vue.js, Node.js',
          },
          {
            icon: 'server-stack',
            content: 'GitLabs',
          },
          {
            icon: 'adjustments-vertical',
            content: 'CI/CD',
          },
          {
            icon: 'user-plus',
            content: 'Team collaboration',
          },
        ],
        content: {
          desktop: {
            renditions: {
              opt: enxiDesktopOpt,
              raw: enxiDesktopOpt,
              alt: 'enixi desktop',
            },
            heading: 'Stack',
            features: [
              {
                description: 'Data visualisation with chartJs',
                id: createUid(),
              },
              { description: 'Whitelabel app', id: createUid() },
              {
                description: 'Installable PWA with workbox and vitePWA',
                id: createUid(),
              },
            ],
          },
          mobile: {
            renditions: {
              opt: enixiMobileOpt,
              raw: enixiMobileOpt,
              alt: 'enixi desktop',
            },
            alt: 'enixi mobile',
            heading: 'Features',
            features: [
              {
                description: 'Advanced User forms evaluation and handling',
                id: createUid(),
              },
              { description: 'Reusable component library', id: createUid() },
              { description: 'Flux pattern with pinia', id: createUid() },
              { description: 'Whitelabel app', id: createUid() },
              {
                description: 'Design system with tailwindcss',
                id: createUid(),
              },
              { description: 'E2E with cypress', id: createUid() },
              { description: 'Integration test with cypress', id: createUid() },
            ],
          },
        },
        altText: 'Website enixi',
        renditions: {
          opt: enixiOpt,
          raw: enixiAlt,
        },
        link: {
          href: 'https://www.enixi.io/',
          icon: 'global-alt',
        },
        live: {
          href: 'https://app.enixi.io/',
          icon: 'cursor-arrow-ripple',
        },
      },
      {
        name: 'Streamdiver',
        id: 'sd-292',
        publishedDate: '12.12.2022',
        cardVersion: 'prominent',
        tags: [
          { mode: 'highlight', name: 'work', id: createUid() },
          { mode: 'elegant', name: 'large-scale', id: createUid() },
        ],
        slideInfos: [
          {
            icon: 'computer-desktop',
            content: 'App developing',
          },
          {
            icon: 'computer-desktop',
            content: 'Editor developing',
          },
          {
            icon: 'code-bracket',
            content: 'HTML, CSS (Tailwindcss)',
          },
          {
            icon: 'code-bracket',
            content: 'JavaScript, Vue.js, Nuxt.js',
          },
          {
            icon: 'server-stack',
            content: 'Jira, Confluence, bitbucket',
          },
          {
            icon: 'server',
            content: 'Microservices',
          },
          {
            icon: 'ai',
            content: 'AI integration',
          },
          { icon: 'puzzle-piece', content: 'Custom player plugins' },
          {
            icon: 'user-plus',
            content: 'Team collaboration',
          },

          // {
          //   icon: 'Icon_Progressive',
          //   content: 'Progressive Enhancement',
          // },
        ],
        altText: 'Website streamdiver',
        renditions: {
          opt: streamdiverOpt,
          raw: streamdiverAlt,
        },
        link: {
          href: 'https://www.streamdiver.com',
          icon: 'global-alt',
        },
      },
      {
        name: 'Portfolio',
        id: 'p-2245',
        publishedDate: '05.08.2020',
        cardVersion: 'secondary',
        tags: [
          { mode: 'emphasize', name: 'personal', id: createUid() },
          { mode: 'elegant', name: 'large-scale', id: createUid() },
        ],
        slideInfos: [
          {
            icon: 'computer-desktop',
            content: 'Website developing',
          },
          {
            icon: 'user-plus',
            content: 'Business building',
          },
          {
            icon: 'code-bracket',
            content: 'HTML, SCSS, JavaScript',
          },
          {
            icon: 'code-bracket',
            content: 'Nuxt3, PWA, Vue3',
          },
          {
            icon: 'adjustments-vertical',
            content: 'CI/CD ',
          },
          {
            icon: 'megaphone',
            content: 'Analytics integration',
          },
          { icon: 'pencil-square', content: 'UI / UX Design' },
        ],
        altText: 'Photo of portfolio landing page',
        renditions: {
          opt: portfolioOpt,
          raw: portfolioAlt,
        },
        link: {
          href: 'https://www.bentec.dev',
          icon: 'global-alt',
        },
      },
      {
        name: 'Chat App',
        id: 'ch-222',
        publishedDate: '05.12.2022',
        cardVersion: 'tertiary',
        tags: [{ mode: 'neutral', name: 'fun', id: createUid() }],
        slideInfos: [
          {
            icon: 'code-bracket',
            content: 'NodeJs, Express',
          },
          {
            icon: 'arrow-down-on-square',
            content: 'Websocket',
          },
        ],
        altText: 'Photo of portfolio landing page',
        renditions: {
          opt: chatAppOpt,
          raw: chatAppAlt,
        },
        link: {
          href: 'https://chat-app-benchis.vercel.app/',
          icon: 'global-alt',
        },
      },
      {
        name: 'Magma Yoga',
        id: 'my-232',
        publishedDate: '05.08.2020',
        cardVersion: 'v3',
        tags: [{ mode: 'highlight', name: 'work', id: createUid() }],
        slideInfos: [
          {
            icon: 'computer-desktop',
            content: 'Plattform developing',
          },
          {
            icon: 'user-plus',
            content: 'Business building',
          },
          // {
          //   icon: 'Icon_Progressive',
          //   content: 'Progressive Enhancement',
          // },
          { icon: 'megaphone', content: 'Marketing' },
          { icon: 'pencil-square', content: 'UI / UX Design' },
        ],
        altText: 'Website photo of magma yoga',
        renditions: {
          opt: magmaOpt,
          raw: magmaAlt,
        },
        link: {
          href: 'https://www.magmayoga.com',
          icon: 'global-alt',
        },
      },
      {
        name: 'Tobias Anderson',
        id: 't-33',
        tags: [{ mode: 'highlight', name: 'work', id: createUid() }],
        cardVersion: 'tertiary',
        publishedDate: '05.08.2020',
        slideInfos: [
          {
            icon: 'computer-desktop',
            content: 'Artist Label',
          },
          {
            icon: 'pencil-square',
            content: 'UI / UX Design',
          },
          {
            icon: 'code-bracket',
            content: 'HTML, SCSS, JavaScript',
          },
          {
            icon: 'building-library',
            content: 'Libraries: BarbaJs, Gsap',
          },
          { icon: 'command-line', content: 'Parcel' },
        ],
        altText: 'Website photo of magma yoga',

        renditions: {
          opt: tobiasOpt,
          raw: tobiasAlt,
        },
        // link: {
        //   href: 'https://www.magmayoga.com',
        //   sprit:
        //     'global-alt'
        // }
      },
      {
        name: 'Mapty',
        tags: [{ mode: 'neutral', name: 'fun', id: createUid() }],
        id: 'm-3',
        cardVersion: 'secondary',
        publishedDate: '05.08.2020',
        slideInfos: [
          {
            icon: 'code-bracket',
            content: 'TML, CSS, JS',
          },
          {
            icon: 'building-library',
            content: 'Leaflet Libary',
          },
          {
            icon: 'wrench-screwdriver',
            content: 'OOP Architecture',
          },
          { icon: 'server', content: 'Geolocation Api' },
          { icon: 'server', content: 'Weather Api' },
          { icon: 'command-line', content: 'Parcel' },
        ],
        altText: 'Picture of application Interface',

        renditions: {
          opt: maptyOpt,
          raw: maptyAlt,
        },
        link: {
          rel: 'nofollow',
          href: 'https://mapty-workouts.netlify.app/',
          icon: 'global-alt',
        },
        platform: {
          rel: 'nowfollow',
          href: 'https://github.com/BenChis/Mapty',
          icon: 'github',
        },
      },
      {
        name: 'Forkify',
        id: 'f-34',
        publishedDate: '05.08.2020',
        cardVersion: 'tertiary',
        tags: [{ mode: 'neutral', name: 'fun', id: createUid() }],
        slideInfos: [
          {
            icon: 'computer-desktop',
            content: 'Forkify',
          },
          {
            icon: 'code-bracket',
            content: 'HTML, CSS, JS',
          },
          {
            icon: 'wrench-screwdriver',
            content: 'MVC Architecture',
          },
          { icon: 'server', content: 'Recipe Api' },
          { icon: 'command-line', content: 'Parcel' },
        ],
        altText: 'Picture of application Interface',
        renditions: {
          opt: forkifyOpt,
          raw: forkifyAlt,
        },
        link: {
          rel: 'nofollow',
          href: 'https://forkify-cooking-suggestions.netlify.app/',
          icon: 'global-alt',
        },
        github: {
          rel: 'nowfollow',
          href: 'https://github.com/BenChis/forkify-course-video',
          icon: 'github',
        },
      },
      {
        name: 'Expense Tracker',
        cardVersion: 'v1',
        tags: [{ mode: 'neutral', name: 'fun', id: createUid() }],
        id: createUid(),
        publishedDate: '05.08.2020',
        slideInfos: [
          {
            icon: 'computer-desktop',
            content: 'Expense Tracker',
          },
          {
            icon: 'code-bracket',
            content: 'HTML, CSS, REACT',
          },
          { icon: 'command-line', content: 'Webpack' },
        ],
        altText: 'Picture of application Interface',
        renditions: {
          opt: expenseTrackerOpt,
          raw: expenseTrackerAlt,
        },
        // link: {
        //   rel: 'nofollow',
        //   href:
        //     'https://forkify-cooking-suggestions.netlify.app/',
        //   icon:
        //     'global-alt'
        // }
        // ,
        // github: {
        //   rel: 'nowfollow',
        //   href:
        //     'https://github.com/BenChis/forkify-course-video',
        //   icon:
        //     'github'
        // }
      },
      {
        name: 'omnifood',
        id: createUid(),
        cardVersion: 'tertiary',
        publishedDate: '05.08.2020',
        tags: [{ mode: 'neutral', name: 'fun', id: createUid() }],
        slideInfos: [
          {
            icon: 'computer-desktop',
            content: 'omnifood',
          },
          {
            icon: 'code-bracket',
            content: 'HTML, CSS',
          },
        ],
        altText: 'Picture of application Interface',

        renditions: {
          opt: omnifoodOpt,
          raw: omnifoodAlt,
        },
        // link: {
        //   rel: 'nofollow',
        //   href:
        //     'https://forkify-cooking-suggestions.netlify.app/',
        //   icon:
        //     'global-alt'
        // }
        // ,
        // github: {
        //   rel: 'nowfollow',
        //   href:
        //     'https://github.com/BenChis/forkify-course-video',
        //   icon:
        //     'github'
        // }
      },
      {
        name: 'Iphone',
        id: createUid(),
        cardVersion: 'v3',
        tags: [{ mode: 'neutral', name: 'fun', id: createUid() }],
        publishedDate: '05.08.2020',
        slideInfos: [
          {
            icon: 'computer-desktop',
            content: 'Expense Tracker',
          },
          {
            icon: 'code-bracket',
            content: 'HTML, CSS, REACT',
          },
          { icon: 'command-line', content: 'Webpack' },
        ],
        altText: 'Picture of application Interface',

        renditions: {
          opt: iphoneOpt,
          raw: iphoneAlt,
        },
        // link: {
        //   rel: 'nofollow',
        //   href:
        //     'https://forkify-cooking-suggestions.netlify.app/',
        //   icon:
        //     'global-alt'
        // }
        // ,
        // github: {
        //   rel: 'nowfollow',
        //   href:enixi
        //     'https://github.com/BenChis/forkify-course-video',
        //   icon:
        //     'github'
        // }
      },
    ],
  });

  // ************* import COMPOSABLES ************* //
  const route = useRoute();

  // ************* GETTERS ************* //
  const activeProject = computed(() => {
    const project = state.list.find(
      (project) => project.id === route.params.id,
    );
    if (!project) console.warn('No active project not found');

    return project;
  });

  return { state, activeProject };
});

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useProjectsStore, import.meta.hot));
}
